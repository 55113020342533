<!-- eslint-disable vue/no-v-html -->
<template>
	<WSelectableDataTable
		:headers="headers"
		:loading="loading"
		:pagination="pagination"
		:value="value"
		@select="onSelect"
		@select-rows="onSelectRows"
		@unselect="onUnselect"
		@unselect-rows="onUnselectRows"
		@update:pagination="onPaginationUpdated($event)"
	>
		<template v-slot:items="{ item }">
			<td v-html="$highlightMatching(item.company, search)" />
			<td v-html="$highlightMatching(item.siret, search)" />
			<td v-html="$highlightMatching(item.client_code, search)" />
		</template>
		<template v-slot:no-data>
			<td v-if="loading" colspan="4" class="text-xs-center" v-text="noDataText" />
			<v-alert v-else color="warning" icon="warning" :value="true">
				{{ noDataText }}
			</v-alert>
		</template>
	</WSelectableDataTable>
</template>

<script>
export default {
	name: 'ApplicationVendorDataTable',
	components: {
		WSelectableDataTable: () => ({
			component: import('@/components/Commons/Tables/WSelectableDataTable')
		})
	},
	props: {
		loading: {
			default: false,
			required: false,
			type: Boolean
		},
		pagination: {
			default: () => ({}),
			required: false,
			type: Object
		},
		value: {
			default: () => [],
			required: false,
			type: Array
		},
		search: {
			default: '',
			required: false,
			type: String
		}
	},
	data: function () {
		return {
			headers: [
				{
					text: this.$t('application.attributes.vendor.company'),
					value: 'company'
				},
				{
					text: this.$t('application.attributes.vendor.unique_identifier'),
					value: 'siret',
					width: '151px'
				},
				{
					text: this.$t('application.attributes.vendor.client_code'),
					value: 'client_code'
				}
			]
		}
	},
	computed: {
		isAdmin: function () {
			return this.selectedAccountingFirm?.isAccountantAdmin ?? false
		},
		noDataText: function () {
			let result = ''
			if (this.loading) {
				result = this.$t('application.info.loading_clients')
			} else if (this.isAdmin) {
				result = this.$t('application.warning.no_client')
			} else {
				result = this.$t('application.warning.no_client_access')
			}

			return result
		}
	},
	methods: {
		onSelect: function (row) {
			this.$emit('select', row)
		},
		onSelectRows: function () {
			this.$emit('select-all')
		},
		onUnselect: function (row) {
			this.$emit('unselect', row)
		},
		onUnselectRows: function () {
			this.$emit('unselect-all')
		},
		onPaginationUpdated: function (paginationData) {
			this.$emit('update:pagination', paginationData)
		}
	}
}
</script>